<template>
  <div class="container">
    <div class="total-grade my-4">

      <h4 class="text-center">Итоговый</h4>

      <div class="my-4">
        <div class="card">

          <div class="card-body">
            <div class="card-title mb-4">
              <h4 class="d-block ml-3">
                Информация о курсе
              </h4>
            </div>

            <div>
              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Название курса:</strong></p></div>
                <div class="col-md-9">
                  {{ totalGrade_form.courseInfos?.course_name }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Язык обучения:</strong></p></div>
                <div class="col-md-9">
                  {{totalGrade_form.courseInfos?.formationEducationProgram?.educationDiscipline?.language?.native_name}}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Курс:</strong></p></div>
                <div class="col-md-9">
                  {{ totalGrade_form.courseInfos?.educationCurriculum?.study_course }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Семестр:</strong></p></div>
                <div class="col-md-9">
                  {{ totalGrade_form.courseInfos?.formationEducationProgram?.semester }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Кредит:</strong></p></div>
                <div class="col-md-9">
                  {{ totalGrade_form.courseInfos?.formationEducationProgram?.credit }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Группы</strong></p></div>
                <div class="col-md-9">
                  <select class="form-control form-select"
                          @input="changeGroup($event)">
                    <option
                        v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...totalGrade_form.studentGroups]"
                        :value="item.id"
                        :selected="item.id === groupId"
                        :key="index">{{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="attendance mt-4" v-if="groupId != 0">
          <DataTable :value="filteredStudents" stripedRows responsiveLayout="scroll">
            <Column field="barcode" header="Баркод"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{ data.last_name }} {{ data.first_name }}
              </template>
            </Column>

            <Column header="Итоговый">
              <template #body="{data}">
                <div v-if="isGymTeacher && data.student_ratings_close_status == 0">
                  <input class="form-control" type="number" max="100" min="0" placeholder="Итоговый"
                         :value="data.student_ratings_total"
                         @change="changeTotalGrade(data.student_ratings_id, $event)">
                </div>
                <div v-else>
                  {{ data.student_ratings_total }}
                </div>
              </template>
            </Column>
          </DataTable>

          <div class="col-md-12 mt-4 text-center" style="min-height: 120px"
               v-if="isGymTeacher && filteredStudents.some(i=>i.student_ratings_close_status==0)">
            <button type="button" class="btn btn-primary" @click="postTotalGrade">
              Закрыть итоговый балл
            </button>
          </div>
        </div>


      </div>
      
    </div>
  </div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: "TotalGrade",
    data() {
      return {
        mdl_course_id: +this.$route.query.mdl_course_id || 1,
        education_courses_id: +this.$route.query.education_courses_id || 1,
        groupId: 0,
      }
    },
    computed: {
      ...mapState('totalGrade', ['totalGrade_form']),
      isGymTeacher() {
        return decryptCheckRole('gymTeacher')
      }
    },
    methods: {
      ...mapActions('totalGrade', ['GET_EDUCATION_COURSE_DATA_BY_ID', 'GET_STUDENTS_BY_MDL_COURSE_ID', 'POST_TOTAL_GRADE_REPORT']),
      ...mapMutations('totalGrade', ['SET_TOTAL_GRADE_REPORT', 'SET_EDUCATION_COURSES_ID', 'UPDATE_TOTAL_GRADE_REPORT']),
      changeGroup(e) {

        this.groupId = e.target.value
        console.log(this.groupId, 'groupId')

        this.filteredStudents = this.totalGrade_form.students.filter(i => i.group_id == this.groupId)

        console.log(this.filteredStudents, 'filteredStudents')
        this.SET_TOTAL_GRADE_REPORT(this.filteredStudents)
      },

      changeTotalGrade(student_ratings_id, e) {
        let total = e.target.value
        this.UPDATE_TOTAL_GRADE_REPORT({student_ratings_id, total})
      },

      async postTotalGrade() {
        const res = await this.POST_TOTAL_GRADE_REPORT()
        this.groupId = 0
        if (res == true) {

          await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.education_courses_id)
          await this.GET_STUDENTS_BY_MDL_COURSE_ID(this.mdl_course_id)
          this.$message({title: 'Оценка', text: 'Оценки успешно выставлены'});
        } else {
          this.$error({title: 'Оценка', text: 'Произошла ошибка'})
        }

      }
    },
    async mounted() {
      await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.education_courses_id)
      await this.GET_STUDENTS_BY_MDL_COURSE_ID(this.mdl_course_id)
      // await this.SET_EDUCATION_COURSES_ID(this.education_courses_id)
    }

  }
</script>

<style scoped>

</style>